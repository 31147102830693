import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Divider,
  Text,
  InputGroup,
  InputLeftAddon,
  localStorageManager,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { getProperty } from "dot-prop";
import { useCaseUpdate } from "@hooks/api/useCaseApi";
import { useCreateCaseStore } from "store/CreateCaseStore";
import ErrorRender from "@components/atom/ErrorRender";
import { InputFieldOptions } from "@config/constant";
import MyButton from "@components/atom/MyButton";
import { maskString, showSuccessToast } from "@utils";
import { useRedStore } from "@store/store";
import { useOrder } from "@hooks/api/useSave";
import { createCaseSaveAsEnquiryButtonClicked } from "@config/event";
import { ChangeEvent, useState } from "react";
import { Icon } from "@iconify/react";

interface ISaveAsEnquiryModal {
  isOpen: boolean;
  onClose: () => void;
  isDisabled?: boolean;
}

function SaveAsEnquiryModal({ isOpen, onClose, isDisabled }: ISaveAsEnquiryModal) {
  const navigate = useNavigate();

  const { saveAsEnquiry, ...rest } = useOrder();
  const currentCase = useRedStore(state => state.localOrderChanges);
  const [editNumber, setEditNumber] = useState<boolean>(false);
  const resetCurrentChanges = useCreateCaseStore((state: any) => state.resetCurrentChanges);

  const provider = useForm({
    defaultValues: {
      name: currentCase?.requestedBy?.name,
      mobile: currentCase?.requestedBy?.mobile,
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = provider;

  const mobileNumber = watch("mobile", "")

  const onCloseHandler = () => {
    onClose();
  };

  function onSuccessCb(data: any) {
    const orderId = getProperty(data?.data?.data, "orderId");
    if (orderId) {
      navigate(`/case-enquiry/${orderId}`);
    }
  }

  const onSubmit = (data: any) => {
    createCaseSaveAsEnquiryButtonClicked({ orderId: currentCase?.orderId, ...data, bookingType: "ENQUIRY" });
    saveAsEnquiry({ options: { requestedBy: data, bookingType: "ENQUIRY" }, onSuccess: onSuccessCb });
    resetCurrentChanges();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e)
    const value = e.target.value;
    if (/^\d+$/.test(value)) {
      provider.setValue("mobile", value)
    }
  }

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onCloseHandler} isCentered autoFocus={false}>
        <ModalOverlay />
        <ModalContent mx={2} bg={"white"}>
          <ModalHeader mb={-2} fontSize={"md"}>
            Save as Enquiry
          </ModalHeader>
          <Divider />
          <ModalBody>
            <Text fontWeight={"normal"} fontSize={"sm"}>
              Please confirm Requestor’s contact name & number.
            </Text>
            <form defaultValue={currentCase?.requestedBy as any} onSubmit={handleSubmit(onSubmit)}>
              <FormControl my={5} isRequired>
                <FormLabel fontSize={"sm"} mb={0.75}>
                  Contact Name
                </FormLabel>
                <Input placeholder="Contact Name" size={"sm"} {...register("name", InputFieldOptions.name)} fontWeight={"semibold"} />
                <ErrorRender errors={errors.name} />
                <Box my={5} />
                <FormLabel fontSize={"sm"} mb={0.75}>
                  Contact Number
                </FormLabel>
                {editNumber ? (
                  <>
                    <InputGroup size={"sm"}>
                      <InputLeftAddon children="+91" />
                      <Input fontSize={"sm"} fontWeight={"semibold"} {...register("mobile", InputFieldOptions.mobile)} placeholder="Contact Number" maxLength={10} onChange={handleChange} />
                    </InputGroup>
                  </>
                ) : (
                  <Flex justifyContent={'space-between'} alignItems={'center'} >
                    <InputGroup size={"sm"}>
                      <InputLeftAddon children="+91" />
                      <Text lineHeight={8} marginLeft={3} fontSize={"sm"} fontWeight={"semibold"}>{maskString(currentCase?.requestedBy?.mobile)} </Text>
                    </InputGroup>
                    <Button onClick={()=>{ setEditNumber(true); provider.setValue("mobile", "") }}><Icon fontSize={16} icon="line-md:edit-twotone"/></Button>
                  </Flex>
                )}
                <ErrorRender errors={errors.mobile} />
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <MyButton variant={"outline"} mr={3} onClick={onCloseHandler} fontWeight={"semibold"} fontSize={"xs"} size={"sm"} rounded={"full"} px={4} isDisabled={rest?.isLoading} label="Close" />

            <MyButton isDisabled={!isValid || isDisabled || (editNumber ? mobileNumber.length != 10 : false)} onClick={handleSubmit(onSubmit)} isLoading={rest?.isLoading} label="Confirm" />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default SaveAsEnquiryModal;
