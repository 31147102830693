import { Box, Button, Flex, Tag, TagLabel, TagLeftIcon, Text, useDisclosure } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import SaveAsEnquiryModal from "@components/molecules/SaveAsEnquiryModal";
import { useRedStore } from "@store/store";
import useCaseObserver from "@hooks/action/useCaseObserver";
import { createCaseSaveAsEnquiryModalClose, createCaseSaveAsEnquiryModalOpen } from "@config/event";
import { maskString } from "@utils";

export default function SaveAsEnquirySection() {
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mobile = localOrderChanges.requestedBy?.mobile;

  const { _isReadyToDispatch } = useCaseObserver();

  const handleOpen = () => {
    onOpen();
    createCaseSaveAsEnquiryModalOpen({ orderId: localOrderChanges?.orderId });
  };

  const handleClose = () => {
    onClose();
    createCaseSaveAsEnquiryModalClose({ orderId: localOrderChanges?.orderId });
  };

  return (
    <Box p={2} m={2} bg={"white"} shadow={"sm"} borderRadius={"md"}>
      <Flex justify={"space-between"}>
        <Tag rounded={"full"} onClick={onOpen} size={"md"} variant="subtle" colorScheme="purple" fontWeight={"semibold"} px={3} py={1.5}>
          <TagLeftIcon as={PhoneIcon} />
          {/* <TagLabel ml={1}>+91 {mobile}</TagLabel> */}
          <TagLabel>+91 {maskString(mobile)}</TagLabel>
        </Tag>
        <Button
          rounded={"full"}
          size={"md"}
          h={8}
          variant="solid"
          fontWeight={"semibold"}
          textTransform={"capitalize"}
          px={4}
          py={1.5}
          onClick={handleOpen}
          ml={1}
          fontSize={"xs"}
          gap={2}
          isDisabled={!_isReadyToDispatch}
          colorScheme="primary"
        >
          <Icon icon={"material-symbols:download"} fontSize={20} /> SAVE AS ENQUIRY
        </Button>
      </Flex>
      {isOpen && <SaveAsEnquiryModal isOpen={isOpen} onClose={handleClose} isDisabled={!_isReadyToDispatch} />}
    </Box>
  );
}

const PhoneIcon = () => <Icon icon={"basil:phone-in-solid"} fontSize={20} />;
const DownloadIcon = () => <Icon icon={"material-symbols:download"} fontSize={20} />;
const LoadingIcon = () => <Icon icon={"line-md:loading-twotone-loop"} fontSize={20} />;
